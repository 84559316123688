export const COMMON_KEYBOARD_KEY = {
	ENTER: 'Enter',
	TAB: 'Tab',
	ARROW_DOWN: 'ArrowDown',
	ARROW_UP: 'ArrowUp',
	ARROW_LEFT: 'ArrowLeft',
	ARROW_RIGHT: 'ArrowRight',
	END: 'End',
	HOME: 'Home',
	ESCAPE: 'Escape',
	SPACE: ' ',
	BACKSPACE: 'Backspace',
	DELETE: 'Delete',
	DIGIT_ONE: '1',
	DIGIT_ZERO: '0',
	MINUS: '-',
	UNDERSCORE: '_',
	EQUAL: '=',
	PLUS: '+',
	COMMA: ',',
	SEMICOLON: ';',
};
